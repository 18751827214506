<template>
  <div class="d-flex-column align-center main">
    <div class="mw-400 input-underline">
      <el-select
        placeholder="Select member"
        v-model="selectVal"
        @change="getPlayerStats(selectVal)"
        class="full-width"
      >
        <el-option
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </div>
    <!--  TABLE START  -->
    <div class="table-wrapper d-flex">
      <div class="el-table">
        <table>
          <colgroup>
            <col name="col_1" />
            <col name="col_2" />
            <col name="col_3" />
            <col name="col_4" />
          </colgroup>

          <thead class="bg-transparent">
            <tr>
              <th colspan="1" rowspan="1" class="col_1 is-leaf">
                <div class="cell">Season</div>
              </th>
              <th colspan="1" rowspan="1" class="col_2 is-leaf">
                <div class="cell">Association</div>
              </th>
              <th colspan="1" rowspan="1" class="col_3 is-leaf">
                <div class="cell">Club Name</div>
              </th>
              <th colspan="1" rowspan="1" class="col_4 is-leaf">
                <div class="cell">Competition Name</div>
              </th>
              <th colspan="1" rowspan="1" class="col_4 is-leaf">
                <div class="cell">Age Level</div>
              </th>
              <th colspan="1" rowspan="1" class="col_4 is-leaf">
                <div class="cell">Team Name</div>
              </th>
              <th colspan="1" rowspan="1" class="col_4 is-leaf">
                <div class="cell">Games Played</div>
              </th>
              <th colspan="1" rowspan="1" class="col_4 is-leaf">
                <div class="cell">Tries</div>
              </th>
              <th colspan="1" rowspan="1" class="col_4 is-leaf">
                <div class="cell">Goals</div>
              </th>
              <th colspan="1" rowspan="1" class="col_4 is-leaf">
                <div class="cell">1pt Field Goals</div>
              </th>
              <th colspan="1" rowspan="1" class="col_4 is-leaf">
                <div class="cell">2pt Field Goals</div>
              </th>
              <th colspan="1" rowspan="1" class="col_4 is-leaf">
                <div class="cell">Total Points</div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(row, index) in tableData" :key="index" class="el-table__row">
              <td v-for="(col, i) in row" :key="i">
                <div class="cell">{{ col }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import { mapActions, mapMutations } from "vuex";
import moment from "moment";
import msg from "@/utils/constants/msg";
import { endpoints, endpointParams } from "@/utils/constants/api";

export default {
  name: "Transactions",
  methods: {
    ...mapActions("views", ["notify"]),
    ...mapMutations("root", ["LOADING"]),
  },
  mounted() {
    this.LOADING(true);
    this.$http
      .get(endpoints.getMembers)
      .then((membersResponse) => {
        const options = membersResponse.data.data.map(({ _id: value, profile }) => ({
          label: `${profile.firstName} ${profile.lastName}`,
          value,
        }));
        this.$set(this, "options", options);
        this.LOADING(false);
      })
      .catch(() => {
        this.notify({
          msg: msg.error.apiError,
          route: this.$route.name,
          type: "warning",
          window,
        });
        this.LOADING(false);
      });
  },
  data() {
    return {
      options: [],
      tableData: [],
      selectVal: "",
    };
  },
  methods: {
    ...mapActions("views", ["notify"]),
    ...mapMutations("root", ["LOADING"]),
    getClubName(data) {
      return get(data, "club.name", get(data, "team.orgtree.club.name", " "));
    },
    async getPlayerStats(id) {
      this.$http
        .get(endpointParams.profile.playerStats(id))
        .then((res) => {
          let transformed = res.data.data.map((line) => ({
            season: get(line, "competition.season", ""),
            assocName: get(line, "team.orgtree.association.name", ""),
            clubName: this.getClubName(line),
            compName: get(line, "competition.name"),
            ageLvl: get(line, "competition.ageLvl"),
            teamName: line.team.name,
            games: line.games,
            tries: line.tries,
            goals: line.goals,
            fieldGoals: line.fieldGoals,
            fieldGoals2pt: line.fieldGoals2pt,
            totalPoints: line.totalPoints,
          }));
          //sort the data in descending order by season value
          transformed = _.orderBy(transformed, ["season"], ["desc"]);
          this.$set(this, "tableData", transformed);
          this.LOADING(false);
        })
        .catch((err) => {
          this.notify({
            msg: msg.error.apiError,
            route: this.$route.name,
            type: "warning",
            window,
          });
          this.LOADING(false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  overflow-x: auto;
  background-color: transparent;

  .col_1 {
    min-width: 40px;
  }

  .col_2 {
    min-width: 60px;
  }

  .col_3 {
    min-width: 100px;
  }

  .col_4 {
    min-width: 100px;
  }

  .cell {
    font-size: 0.9em;
    text-align: center;
    white-space: nowrap;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr,
      th {
        background-color: transparent;
      }
    }

    tbody {
      tr {
        background-color: white;
      }
    }
  }
}

.bg-transparent {
  background-color: transparent !important;
}
</style>
